/**-----------------------------------------------------------------------------------------
* Copyright © 2023 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { forwardRef, Directive, Input, EventEmitter, ViewContainerRef, Component, ViewChild, HostBinding, TemplateRef, InjectionToken, ApplicationRef, Injectable, Inject, Optional, NgModule } from '@angular/core';
import * as i1$1 from '@angular/animations';
import { style, animate } from '@angular/animations';
import { take } from 'rxjs/operators';
import { validatePackage } from '@progress/kendo-licensing';
import * as i1 from '@progress/kendo-angular-l10n';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { xIcon, checkOutlineIcon, exclamationCircleIcon, xOutlineIcon, infoCircleIcon } from '@progress/kendo-svg-icons';
import { guid } from '@progress/kendo-angular-common';
import * as i3 from '@progress/kendo-angular-icons';
import { IconsModule } from '@progress/kendo-angular-icons';
import * as i5 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["container"];
function NotificationComponent_kendo_icon_wrapper_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-icon-wrapper", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.typeIconClass())("svgIcon", ctx_r0.typeSVGIcon());
  }
}
function NotificationComponent_ng_template_4_Template(rf, ctx) {}
function NotificationComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" ", ctx_r2.templateString, " ");
  }
}
function NotificationComponent_span_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 9)(1, "span", 10);
    i0.ɵɵlistener("click", function NotificationComponent_span_8_Template_span_click_1_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.onCloseClick());
    });
    i0.ɵɵelement(2, "kendo-icon-wrapper", 11);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵattribute("title", ctx_r4.closeButtonTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("svgIcon", ctx_r4.xIcon);
  }
}
const _c3 = ["group"];
class NotificationSettings {
  constructor() {
    /**
     * Specifies the time in milliseconds after which the
     * Notification will hide
     * ([see example]({% slug hiding_notifications %}#toc-definig-a-delay-before-hiding)).
     * Defaults to `5000`.
     */
    this.hideAfter = 5000;
    /**
     * Defines the position of the Notification
     * ([see example]({% slug positioning_notification %})).
     *
     * The possible values are:
     * * `horizontal: 'left'|'center'|'right'`
     * * `vertical: 'top'|'bottom'`
     */
    this.position = {
      horizontal: 'right',
      vertical: 'top'
    };
    /**
     * Specifies the animation settings of the Notification
     * ([see example]({% slug animations_notification %})).
     *
     * The possible values are:
     * * `duration`&mdash;Accepts a number in milliseconds. Defaults to `500ms`.
     * * `type?: 'slide'| (Default) 'fade'`
     */
    this.animation = {
      type: 'fade',
      duration: 500
    };
    /**
     * Specifies if the Notification will require a user action to hide.
     * If the property is set to `true`, the Notification renders a **Close** button
     * ([see example]({% slug hiding_notifications %}#toc-defining-a-closable-notification)).
     *
     * The possible values are:
     * * (Default) `false`
     * * `true`
     */
    this.closable = false;
    /**
     * Specifies the type of the Notification
     * ([see example]({% slug types_notification %})).
     *
     * The possible values are:
     * * `style: (Default) 'none'|'success'|'error'|'warning'|'info'`
     * * `icon: 'true'|'false'`
     */
    this.type = {
      style: 'none',
      icon: true
    };
    /**
     * The value of the Notification element `aria-label` attribute.
     * @default 'Notification'
     */
    this.notificationLabel = 'Notification';
  }
}

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-notification',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1703060563,
  version: '14.3.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * @hidden
 */
function slideAnimation(height, duration) {
  return [style({
    overflow: 'hidden',
    height: 0
  }), animate(`${duration}ms ease-in`, style({
    height: `${height}px`
  }))];
}
/**
 * @hidden
 */
function slideCloseAnimation(height, duration) {
  return [style({
    height: `${height}px`
  }), animate(`${duration}ms ease-in`, style({
    overflow: 'hidden',
    height: 0
  }))];
}
/**
 * @hidden
 */
function fadeAnimation(duration) {
  return [style({
    opacity: 0
  }), animate(`${duration}ms ease-in`, style({
    opacity: 1
  }))];
}
/**
 * @hidden
 */
function fadeCloseAnimation(duration) {
  return [style({
    opacity: 1
  }), animate(`${duration}ms ease-in`, style({
    opacity: 0
  }))];
}

/**
 * @hidden
 */
class LocalizedMessagesDirective extends ComponentMessages {
  constructor(service) {
    super();
    this.service = service;
  }
}
LocalizedMessagesDirective.ɵfac = function LocalizedMessagesDirective_Factory(t) {
  return new (t || LocalizedMessagesDirective)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
LocalizedMessagesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LocalizedMessagesDirective,
  selectors: [["", "kendoNotificationLocalizedMessages", ""]],
  inputs: {
    closeTitle: "closeTitle"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: ComponentMessages,
    useExisting: forwardRef(() => LocalizedMessagesDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizedMessagesDirective, [{
    type: Directive,
    args: [{
      providers: [{
        provide: ComponentMessages,
        useExisting: forwardRef(() => LocalizedMessagesDirective)
      }],
      selector: `[kendoNotificationLocalizedMessages]`
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, {
    closeTitle: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 *
 */
class NotificationComponent {
  constructor(cdr, element, renderer, builder, localizationService) {
    this.cdr = cdr;
    this.element = element;
    this.renderer = renderer;
    this.builder = builder;
    this.localizationService = localizationService;
    /**
     * @hidden
     */
    this.xIcon = xIcon;
    this.contentId = `k-${guid()}`;
    this.close = new EventEmitter();
    this.width = null;
    this.height = null;
    this.notificationLabel = 'Notification';
    this.defaultHideAfter = 5000;
    this.animationEnd = new EventEmitter();
    this.rtl = false;
    validatePackage(packageMetadata);
    this.dynamicRTLSubscription = localizationService.changes.subscribe(({
      rtl
    }) => {
      this.rtl = rtl;
      this.direction = this.rtl ? 'rtl' : 'ltr';
    });
  }
  get containerClass() {
    return true;
  }
  get closeButtonTitle() {
    return this.closeTitle || this.localizationService.get('closeTitle');
  }
  notificationClasses() {
    return `${this.type ? this.typeClass() : ''}
            ${this.closable ? 'k-notification-closable' : ''}`;
  }
  ngOnInit() {
    clearTimeout(this.hideTimeout);
  }
  ngOnDestroy() {
    clearTimeout(this.hideTimeout);
    if (this.dynamicRTLSubscription) {
      this.dynamicRTLSubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    if (!this.closable && !this.animation) {
      this.setHideTimeout();
    }
    if (!this.closable && this.animation) {
      this.animationEnd.pipe(take(1)).subscribe(() => this.setHideTimeout());
    }
    if (this.animation) {
      this.animate(this.animation);
    }
  }
  typeClass() {
    return {
      'none': '',
      'success': 'k-notification-success',
      'warning': 'k-notification-warning',
      'error': 'k-notification-error',
      'info': 'k-notification-info'
    }[this.type.style];
  }
  typeIconClass() {
    return {
      'none': '',
      'success': 'check-outline',
      'warning': 'exclamation-circle',
      'error': 'x-outline',
      'info': 'info-circle'
    }[this.type.style];
  }
  typeSVGIcon() {
    return {
      'none': null,
      'success': checkOutlineIcon,
      'warning': exclamationCircleIcon,
      'error': xOutlineIcon,
      'info': infoCircleIcon
    }[this.type.style];
  }
  onCloseClick() {
    clearTimeout(this.hideTimeout);
    this.hide();
  }
  hide(customComponent) {
    const elementHeight = getComputedStyle(this.element.nativeElement).height;
    if (this.animation && elementHeight) {
      this.animate(this.animation, true);
      this.animationEnd.subscribe(() => {
        this.emitClose(customComponent);
      });
      return;
    }
    this.emitClose(customComponent);
  }
  setHideTimeout() {
    const hideAfter = this.hideAfter || this.defaultHideAfter;
    this.hideTimeout = window.setTimeout(() => this.onCloseClick(), hideAfter);
  }
  emitClose(customComponent) {
    if (customComponent) {
      customComponent.destroy();
    }
    this.close.emit();
  }
  play(animation, animatedElement) {
    const factory = this.builder.build(animation);
    const element = this.element.nativeElement;
    this.renderer.addClass(element, 'k-notification-container-animating');
    let player = factory.create(animatedElement);
    player.onDone(() => {
      this.renderer.removeClass(element, 'k-notification-container-animating');
      this.animationEnd.emit();
      if (player) {
        player.destroy();
        player = null;
      }
    });
    player.play();
  }
  animate(animation, onclose) {
    const element = this.element.nativeElement;
    const duration = animation.duration;
    const height = element.offsetHeight;
    const generatedAnimation = animation.type === 'slide' ? this.slideAnimation(height, duration, onclose) : this.fadeAnimation(duration, onclose);
    this.play(generatedAnimation, element);
  }
  slideAnimation(height, duration, onclose) {
    return onclose ? slideCloseAnimation(height, duration) : slideAnimation(height, duration);
  }
  fadeAnimation(duration, onclose) {
    return onclose ? fadeCloseAnimation(duration) : fadeAnimation(duration);
  }
}
NotificationComponent.ɵfac = function NotificationComponent_Factory(t) {
  return new (t || NotificationComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1$1.AnimationBuilder), i0.ɵɵdirectiveInject(i1.LocalizationService));
};
NotificationComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NotificationComponent,
  selectors: [["kendo-notification"]],
  viewQuery: function NotificationComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7, ViewContainerRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  hostVars: 3,
  hostBindings: function NotificationComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.direction);
      i0.ɵɵclassProp("k-notification-container", ctx.containerClass);
    }
  },
  inputs: {
    templateRef: "templateRef",
    templateString: "templateString",
    width: "width",
    height: "height",
    notificationLabel: "notificationLabel",
    cssClass: "cssClass",
    hideAfter: "hideAfter",
    closable: "closable",
    type: "type",
    animation: "animation"
  },
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.notification'
  }])],
  decls: 9,
  vars: 16,
  consts: () => {
    let i18n_1;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The title of the close button
       * @meaning kendo.notification.closeTitle
       */
      const MSG_C__PROJECT_SOLINFO_134A_SOLINFO_SOLUTIONSOUTIEN_WEBAPP_NODE_MODULES__PROGRESS_KENDO_ANGULAR_NOTIFICATION_FESM2020_PROGRESS_KENDO_ANGULAR_NOTIFICATION_MJS_2 = goog.getMsg("Close");
      i18n_1 = MSG_C__PROJECT_SOLINFO_134A_SOLINFO_SOLUTIONSOUTIEN_WEBAPP_NODE_MODULES__PROGRESS_KENDO_ANGULAR_NOTIFICATION_FESM2020_PROGRESS_KENDO_ANGULAR_NOTIFICATION_MJS_2;
    } else {
      i18n_1 = $localize`:kendo.notification.closeTitle|The title of the close button:Close`;
    }
    return [["kendoNotificationLocalizedMessages", "", "closeTitle", i18n_1], ["role", "alert", "aria-live", "polite", 3, "ngClass"], ["innerCssClass", "k-notification-status", 3, "name", "svgIcon", 4, "ngIf"], [1, "k-notification-content", 3, "id"], [3, "ngIf", "ngTemplateOutlet"], [3, "ngIf"], ["container", ""], ["class", "k-notification-actions", "aria-hidden", "true", 4, "ngIf"], ["innerCssClass", "k-notification-status", 3, "name", "svgIcon"], ["aria-hidden", "true", 1, "k-notification-actions"], [1, "k-notification-action", "k-notification-close-action", 3, "click"], ["name", "x", 3, "svgIcon"]];
  },
  template: function NotificationComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementContainer(0, 0);
      i0.ɵɵelementStart(1, "div", 1);
      i0.ɵɵtemplate(2, NotificationComponent_kendo_icon_wrapper_2_Template, 1, 2, "kendo-icon-wrapper", 2);
      i0.ɵɵelementStart(3, "div", 3);
      i0.ɵɵtemplate(4, NotificationComponent_ng_template_4_Template, 0, 0, "ng-template", 4)(5, NotificationComponent_ng_template_5_Template, 1, 1, "ng-template", 5);
      i0.ɵɵelementContainer(6, null, 6);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(8, NotificationComponent_span_8_Template, 3, 2, "span", 7);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵclassMapInterpolate1("k-notification ", ctx.notificationClasses(), "");
      i0.ɵɵstyleProp("height", ctx.height, "px")("width", ctx.width, "px");
      i0.ɵɵproperty("ngClass", ctx.cssClass);
      i0.ɵɵattribute("aria-describedby", ctx.contentId)("aria-label", ctx.notificationLabel);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.type && ctx.type.icon && ctx.type.style !== "none");
      i0.ɵɵadvance();
      i0.ɵɵproperty("id", ctx.contentId);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.templateRef)("ngTemplateOutlet", ctx.templateRef);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.templateString);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.closable);
    }
  },
  dependencies: [i3.IconWrapperComponent, LocalizedMessagesDirective, i5.NgClass, i5.NgIf, i5.NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-notification',
      template: `
    <ng-container kendoNotificationLocalizedMessages
        i18n-closeTitle="kendo.notification.closeTitle|The title of the close button"
        closeTitle="Close"
    >
    </ng-container>
    <div class="k-notification {{ notificationClasses() }}"
        [ngClass]="cssClass"
        [style.height.px]="height"
        [style.width.px]="width"
        role="alert"
        aria-live="polite"
        [attr.aria-describedby]="contentId"
        [attr.aria-label]="notificationLabel">
        <kendo-icon-wrapper
            *ngIf="type && type.icon && type.style !== 'none'"
            innerCssClass="k-notification-status"
            [name]="typeIconClass()"
            [svgIcon]="typeSVGIcon()"
            >
        </kendo-icon-wrapper>
        <div [id]="contentId" class="k-notification-content">
            <ng-template
                [ngIf]="templateRef"
                [ngTemplateOutlet]="templateRef">
            </ng-template>
            <ng-template
                [ngIf]="templateString">
                {{ templateString }}
            </ng-template>
            <ng-container #container></ng-container>
        </div>

        <span *ngIf="closable" class="k-notification-actions" aria-hidden="true">
            <span class="k-notification-action k-notification-close-action" [attr.title]="closeButtonTitle" (click)="onCloseClick()">
                <kendo-icon-wrapper name="x" [svgIcon]="xIcon"></kendo-icon-wrapper>
            </span>
        </span>
    </div>
  `,
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.notification'
      }]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i1$1.AnimationBuilder
    }, {
      type: i1.LocalizationService
    }];
  }, {
    container: [{
      type: ViewChild,
      args: ['container', {
        read: ViewContainerRef,
        static: true
      }]
    }],
    templateRef: [{
      type: Input
    }],
    templateString: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    notificationLabel: [{
      type: Input
    }],
    cssClass: [{
      type: Input
    }],
    hideAfter: [{
      type: Input
    }],
    closable: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    direction: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    containerClass: [{
      type: HostBinding,
      args: ['class.k-notification-container']
    }]
  });
})();

/**
 * @hidden
 */
class NotificationContainerComponent {
  constructor(element, renderer, resolver) {
    this.element = element;
    this.renderer = renderer;
    this.resolver = resolver;
    this.id = '';
    this.notifications = [];
  }
  ngOnDestroy() {
    this.notifications.forEach(notification => {
      if (notification.closeClickSubscription) {
        notification.closeClickSubscription.unsubscribe();
      }
    });
    this.notifications = [];
  }
  addNotification(settings) {
    this.position = settings.position;
    this.id = `${this.position.horizontal} ${this.position.vertical}`;
    const factory = this.resolver.resolveComponentFactory(NotificationComponent);
    const notificationRef = this.container.createComponent(factory);
    this.applySettings(notificationRef, settings);
    let customComponent = null;
    if (typeof settings.content === 'function') {
      const customFactory = this.resolver.resolveComponentFactory(settings.content);
      customComponent = notificationRef.instance.container.createComponent(customFactory);
    }
    notificationRef.changeDetectorRef.detectChanges();
    this.notifications.push(notificationRef.instance);
    if (settings.appendTo) {
      this.applyAbsolutePosition(settings.appendTo);
    }
    this.applyPosition();
    this.applyContainerWrap();
    return {
      afterHide: notificationRef.instance.close,
      hide: () => notificationRef.instance.hide(customComponent),
      notification: notificationRef,
      content: customComponent || null
    };
  }
  hide(notificationRef) {
    const instance = notificationRef.instance;
    const index = this.notifications.indexOf(instance);
    this.notifications.splice(index, 1);
    if (instance.closeClickSubscription) {
      instance.closeClickSubscription.unsubscribe();
    }
    instance.templateRef = null;
    instance.templateString = null;
    notificationRef.destroy();
  }
  applyContainerWrap() {
    const value = this.position.horizontal === 'right' ? 'wrap-reverse' : 'wrap';
    this.renderer.setStyle(this.group.nativeElement, 'flex-wrap', value);
  }
  applySettings(notificationRef, settings) {
    const notification = notificationRef.instance;
    const content = settings.content;
    const animation = settings.animation || null;
    notification.closeClickSubscription = notification.close.subscribe(() => this.hide(notificationRef));
    if (typeof content === 'string') {
      notification.templateString = content;
    }
    if (content instanceof TemplateRef) {
      notification.templateRef = content;
    }
    notification.animation = animation;
    const type = settings.type;
    if (type && type.style === undefined) {
      type.style = 'none';
    }
    if (type && type.icon === undefined) {
      type.icon = true;
    }
    notification.type = type;
    notification.closeTitle = settings.closeTitle;
    if (settings.cssClass) {
      notification.cssClass = settings.cssClass;
    }
    notification.closable = settings.closable;
    notification.hideAfter = settings.hideAfter;
    notification.width = settings.width;
    notification.height = settings.height;
  }
  applyAbsolutePosition(appendToContainer) {
    const appendTo = appendToContainer.element.nativeElement;
    const el = this.element.nativeElement.children[0];
    if (window.getComputedStyle(appendTo).position === 'static') {
      this.renderer.setStyle(appendTo, 'position', 'relative');
    }
    this.renderer.setStyle(el, 'position', 'absolute');
  }
  applyPosition() {
    const element = this.element.nativeElement.children[0];
    const elementHalfWidth = element.getBoundingClientRect().width / 2;
    const positionStyles = this.setContainerPosition(this.position, elementHalfWidth);
    Object.keys(positionStyles).forEach(cssStyle => {
      element.style[cssStyle] = positionStyles[cssStyle];
    });
  }
  setContainerPosition(position, offsetMargin) {
    const positionLayout = {
      horizontal: {
        left: {
          left: 0,
          alignItems: 'flex-start'
        },
        right: {
          right: 0,
          alignItems: 'flex-start'
        },
        center: {
          left: '50%',
          marginLeft: `${-offsetMargin}px`,
          alignItems: 'center'
        }
      },
      vertical: {
        top: {
          top: 0
        },
        bottom: {
          bottom: 0
        }
      }
    };
    const horizontal = positionLayout.horizontal[position.horizontal];
    const vertical = positionLayout.vertical[position.vertical];
    return Object.assign({}, horizontal, vertical);
  }
}
NotificationContainerComponent.ɵfac = function NotificationContainerComponent_Factory(t) {
  return new (t || NotificationContainerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ComponentFactoryResolver));
};
NotificationContainerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NotificationContainerComponent,
  selectors: [["kendo-notification-container"]],
  viewQuery: function NotificationContainerComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7, ViewContainerRef);
      i0.ɵɵviewQuery(_c3, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.group = _t.first);
    }
  },
  inputs: {
    id: "id"
  },
  decls: 4,
  vars: 0,
  consts: [[1, "k-notification-group"], ["group", ""], ["container", ""]],
  template: function NotificationContainerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0, 1);
      i0.ɵɵelementContainer(2, null, 2);
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationContainerComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-notification-container',
      template: `
    <div #group class="k-notification-group">
        <ng-container #container></ng-container>
    </div>
  `
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ComponentFactoryResolver
    }];
  }, {
    container: [{
      type: ViewChild,
      args: ['container', {
        read: ViewContainerRef,
        static: true
      }]
    }],
    group: [{
      type: ViewChild,
      args: ['group', {
        static: true
      }]
    }],
    id: [{
      type: Input
    }]
  });
})();

/**
 * Used to inject the Notification container. If not provided, the first root component of
 * the application is used.
 *
 * > The `NOTIFICATION_CONTAINER` can be used only with the [`NotificationService`]({% slug api_notification_notificationservice %}) class.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Notification module
 * import { NotificationModule, NOTIFICATION_CONTAINER } from '@progress/kendo-angular-notification';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { ElementRef, NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, NotificationModule], // import Notification module
 *     bootstrap:    [AppComponent],
 *     providers: [{
 *       provide: NOTIFICATION_CONTAINER,
 *       useFactory: () => {
 *          //return the container ElementRef, where the notification will be injected
 *          return { nativeElement: document.body } as ElementRef;
 *       }
 *     }]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 * ```
 */
const NOTIFICATION_CONTAINER = new InjectionToken('Notification Container');
/**
 * A service for opening Notification components dynamically
 * ([see example]({% slug overview_notification %})).
 *
 * @export
 * @class NotificationService
 */
class NotificationService {
  /**
   * @hidden
   */
  constructor(resolver, injector, container) {
    this.resolver = resolver;
    this.injector = injector;
    this.container = container;
    this.notificationContainers = [];
    this.position = {
      horizontal: 'right',
      vertical: 'top'
    };
  }
  /**
   * Opens a Notification component. Created Notification are mounted
   * in the DOM directly in the root application component.
   *
   * @param {NotificationSettings} settings - The settings which define the Notification.
   *
   * @returns {NotificationRef} - A reference to the Notification object and the convenience properties.
   */
  show(settings) {
    if (!settings) {
      throw new Error('NotificationSettings settings are required');
    }
    const target = this.findGroupContainer(settings);
    const position = settings.position || this.position;
    const currentId = `${position.horizontal} ${position.vertical}`;
    let container;
    let notificationRef;
    let notificationContainer = this.notificationContainers.find(c => target.nativeElement.contains(c.element.nativeElement) && c.id === currentId);
    if (!notificationContainer) {
      container = this.resolver.resolveComponentFactory(NotificationContainerComponent).create(this.injector);
      notificationContainer = container.instance;
      this.appRef.attachView(container.hostView);
      const hostViewElement = container.location.nativeElement;
      const groupContainer = this.findGroupContainer(settings);
      if (!groupContainer) {
        throw new Error(`
                    View Container not found! Inject the NOTIFICATION_CONTAINER or define a specific ViewContainerRef via
                    the appendTo option. See http://www.telerik.com/kendo-angular-ui/components/notification/api/NOTIFICATION_CONTAINER/
                    for more details.
                `);
      }
      groupContainer.nativeElement.appendChild(hostViewElement);
      this.notificationContainers.push(notificationContainer);
    }
    settings.position = position;
    // eslint-disable-next-line prefer-const
    notificationRef = notificationContainer.addNotification(settings);
    return notificationRef;
  }
  get appRef() {
    if (!this.applicationRef) {
      this.applicationRef = this.injector.get(ApplicationRef);
    }
    return this.applicationRef;
  }
  findGroupContainer(settings) {
    let container;
    if (settings.appendTo) {
      container = settings.appendTo.element;
    } else if (this.container) {
      container = this.container;
    } else {
      const appRoot = this.appRef.components && this.appRef.components[0];
      container = appRoot ? appRoot.location : null;
    }
    return container;
  }
}
NotificationService.ɵfac = function NotificationService_Factory(t) {
  return new (t || NotificationService)(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(NOTIFICATION_CONTAINER, 8));
};
NotificationService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NotificationService,
  factory: NotificationService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ComponentFactoryResolver
    }, {
      type: i0.Injector
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [NOTIFICATION_CONTAINER]
      }, {
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Notification component.
 *
 * The package exports:
 * - `NotificationService`&mdash;The Notification service class.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Notification module
 * import { NotificationModule } from '@progress/kendo-angular-notification';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * _@NgModule{{
 *    declarations: [AppComponent], // declare app component
 *    imports:      [BrowserModule, NotificationModule], // import NotificationModule module
 *    bootstrap:    [AppComponent]
 * }}
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 * ```
 */
class NotificationModule {}
NotificationModule.ɵfac = function NotificationModule_Factory(t) {
  return new (t || NotificationModule)();
};
NotificationModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NotificationModule
});
NotificationModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [NotificationService],
  imports: [[CommonModule, IconsModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationModule, [{
    type: NgModule,
    args: [{
      declarations: [NotificationComponent, NotificationContainerComponent, LocalizedMessagesDirective],
      entryComponents: [NotificationComponent, NotificationContainerComponent],
      imports: [CommonModule, IconsModule],
      exports: [NotificationComponent, NotificationContainerComponent],
      providers: [NotificationService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NOTIFICATION_CONTAINER, NotificationComponent, NotificationContainerComponent, NotificationModule, NotificationService, NotificationSettings };